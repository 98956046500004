import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import promoVideo from '../assets/videos/home-video.mp4';

import {
  BeautyCard,
  Card,
  CardTitle,
  FashionCard,
  Layout,
  NewsCard,
  Post,
  Seo,
  VideoCard,
} from '@app/components';
import {
  collection,
  where,
  limit,
  getDocs,
  query,
  orderBy,
} from 'firebase/firestore';
import {db} from '../../firebase';

type HomePropTypes = {
  data: {
    headline: PostPropType;
    carousel: PostPropType;
    authorLastPost: PostPropType;
    centerPost: PostPropType;
    video: PostPropType;
    agendaTabs: PostPropType;
    surgicalHairTreatmentsTabs: PostPropType;
    medicalHairTreatmentsTabs: PostPropType;
    beautyTabs: PostPropType;
    fashionTabs: PostPropType;
    lifeTabs: PostPropType;
    healthTabs: PostPropType;
    tripTabs: PostPropType;
  };
  pageContext: {
    language: string;
  };
};

type PostPropType = {
  nodes: {
    excerpt: string;
    frontmatter: {
      url: string;
      title: string;
      author: string;
      date: string;
      cover: any;
      imageUrl: string;
    };
  }[];
};

const Home: React.FC<HomePropTypes> = ({data, pageContext}) => {
  const [carouselItems, setCarouselItems] = useState(data.headline.nodes);
  const [sbbTvItems, setSbbTvItems] = useState(data.video.nodes);
  const [beautyItems, setBeautyItems] = useState(data.beautyTabs.nodes);
  const [surgicalHairTreatmentsItems, setSurgicalHairTreatmentsItems] =
    useState(data.surgicalHairTreatmentsTabs.nodes);
  const [medicalHairTreatmentsItems, setMedicalHairTreatmentsItems] = useState(
    data.medicalHairTreatmentsTabs.nodes,
  );

  const SCOPE_OPTIONS = {
    scope: 'pages.Index',
  };
  const {t} = useLocale();

  useEffect(() => {
    //getCarouselData();
    /*getAgendaData();
    getSbbTvData();
    //getBeautyData();
    getCategoriesData(
      t('actions.beautyTab', SCOPE_OPTIONS),
      beautyItems,
      setBeautyItems,
    );
    getCategoriesData(
      t('actions.fashionTab', SCOPE_OPTIONS),
      fashionItems,
      setFashionItems,
    );
    getCategoriesData(
      t('actions.lifeTab', SCOPE_OPTIONS),
      lifeItems,
      setLifeItems,
    );
    getCategoriesData(
      t('actions.plasticSurgeryTab', SCOPE_OPTIONS),
      plasticSurgeryItems,
      setPlasticSurgeryItems,
    );
    getCategoriesData(
      t('actions.tripTab', SCOPE_OPTIONS),
      tripItems,
      setTripItems,
    );*/
  }, []);

  const getCarouselData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...data.headline.nodes].slice(
        0,
        15,
      );

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setCarouselItems(showingPosts);
      setIsRender(true);
      //setIsLoad(true);
    };

    unSub();
  };

  const getAgendaData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where(
          'categories',
          'array-contains',
          t('actions.agenda', SCOPE_OPTIONS),
        ),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...agendaItems].slice(0, 15);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setAgendaItems(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  const getSbbTvData = () => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where('categories', 'array-contains', 'Youtube'),
        orderBy('createAt', 'desc'),
        limit(15),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
            url: doc.data().url,
          },
        });
      });

      const showingPosts = [...mappingData, ...sbbTvItems].slice(0, 3);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setSbbTvItems(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  const getCategoriesData = (category: string, state: any, stateFunc: any) => {
    const mappingData: any = [];
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('language', '==', pageContext.language),
        where('categories', 'array-contains', category),
        orderBy('createAt', 'desc'),
        limit(3),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
      });

      const showingPosts = [...mappingData, ...state].slice(0, 3);

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      stateFunc(showingPosts);
      //setIsLoad(true);
    };

    unSub();
  };

  return (
    <Layout boxed>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div>
        <div className="promo-video-container">
          {
            <video loop autoPlay muted playsInline>
              <source src={promoVideo} type="video/mp4"></source>
            </video>
          }
        </div>

        <div className="px-6 py-4 layout__inner">
          <div className="pt-4 pb-8 lg:pt-8">
            <CardTitle title="HAIRMEDICARETURKEY TV" titleColor="black" />
            <div className="grid lg:grid-cols-12 gap-8 mb-8">
              {sbbTvItems.map((video, index) => (
                <div className="lg:col-span-4">
                  <VideoCard
                    key={index}
                    title={video.frontmatter.title}
                    description={video.excerpt}
                    author={video.frontmatter.author}
                    date={video.frontmatter.date}
                    url={video.frontmatter.url}
                    cover={video.frontmatter?.cover?.childImageSharp.fluid}
                    imageUrl={video.frontmatter.imageUrl}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className=" pb-8">
            <div className="">
              <iframe
                style={{width: '100%'}}
                height="400"
                src="https://www.youtube.com/embed/GCsLDgjxf1I?si=HQfgpqrcXZMlacfD"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>

          <div className="pt-8 pb-8">
            <FashionCard
              title={t('actions.surgicalHairTreatmentsTab', SCOPE_OPTIONS)}
              titleColor="pink"
              fashionCardList={[
                {
                  title: '',
                  posts: surgicalHairTreatmentsItems,
                },
              ]}
            />
          </div>
          <div className="pt-8 pb-8">
            <BeautyCard
              title={t('actions.medicalHairTreatmentsTab', SCOPE_OPTIONS)}
              titleColor="red"
              beautyCardList={[
                {
                  title: '',
                  posts: medicalHairTreatmentsItems,
                },
              ]}
            />
          </div>
          <div className="pt-8 pb-8">
            <BeautyCard
              title={t('actions.blog', SCOPE_OPTIONS)}
              titleColor="red"
              beautyCardList={[
                {
                  title: '',
                  posts: beautyItems,
                },
              ]}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query ($language: String!) {
    headline: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {in: ["Blog"]}}
      }
      limit: 15
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            publicURL
            childImageSharp {
              resize(width: 150, height: 150) {
                src
              }
            }
          }
        }
      }
    }

    authorLastPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {in: ["Blog"]}}
      }
      limit: 12
    ) {
      nodes {
        excerpt(pruneLength: 80)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    centerPost: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {nin: ["Dergi", "Magazine"]}
        }
      }
      skip: 4
      limit: 1
    ) {
      nodes {
        excerpt(pruneLength: 280)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    video: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {eq: "Youtube"}}
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 130)
        frontmatter {
          title
          author
          date(formatString: "MMMM D, YYYY", locale: $language)
          url
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    beautyTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {language: {eq: $language}, categories: {in: ["Blog"]}}
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    surgicalHairTreatmentsTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {
            in: ["Cerrahi Saç Tedavileri", "Surgical Hair Treatments"]
          }
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    medicalHairTreatmentsTabs: allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {
          language: {eq: $language}
          categories: {
            in: ["Medical Hair Treatments", "Medikal Saç Tedavileri"]
          }
        }
      }
      limit: 3
    ) {
      nodes {
        excerpt(pruneLength: 400)
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY", locale: $language)
          cover {
            childImageSharp {
              resize(width: 910, height: 600) {
                src
              }
            }
          }
        }
      }
    }

    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
